<template>
  <div
    class="general-error"
  >
    <h3
      class="text-center outerSpace--text mb-3"
    >
      {{ $t('general.error') }}
    </h3>

    <p
      class="outerSpace--text ma-0 general-error-text"
    >
      {{ $t('general.general_error') }}
    </p>

      <div class="contact-container-options mt-8">
        <WhatsappButton
            :height="$device.mobile ? '90px' : '90px'"
            :max-height="$device.mobile ? '90px' : '90px'"
            :width= "$device.mobile ? '98px' : '147px'"
            :max-width= "$device.mobile ? '98px' : '147px'"
            :class="[
              !$device.mobile ? 'mr-3' : 'mr-2'
            ]"
        ></WhatsappButton>
        <SupportPhoneButton
            :height="$device.mobile ? '90px' : '90px'"
            :max-height="$device.mobile ? '90px' : '90px'"
            :width= "$device.mobile ? '98px' : '147px'"
            :max-width= "$device.mobile ? '98px' : '147px'"
        ></SupportPhoneButton>
      </div>
    <div class="email-container-label mt-8">
      <SupportEmail></SupportEmail>
    </div>
  </div>
</template>

<script>
  import SupportPhoneButton from "@/components/general/SupportPhoneButton.vue";
  import WhatsappButton from "@/components/general/WhatsappButton.vue";
  import SupportEmail from "@/components/general/SupportEmail.vue";

  export default {
    name: 'GeneralError',
    components: {SupportEmail, WhatsappButton, SupportPhoneButton},
  };
</script>

<style lang="scss" scoped>
  .general-error {
    .general-error-text {
      font-family: Roboto;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      text-align: justify;
      letter-spacing: -1px;
    }
    .contact-container-options {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
    }
    .email-container-label {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .general-error {
      .general-error-text {
        font-family: Roboto;
        font-weight: normal;
        font-size: 15px;
        line-height: 17px;
        text-align: justify;
        letter-spacing: -5%;
      }
      .contact-container-options {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .general-error {
      .general-error-text {
        font-family: Roboto;
        font-weight: normal;
        font-size: 15px;
        line-height: 17px;
        text-align: justify;
        letter-spacing: -5%;
      }
      .contact-container-options {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .general-error {
      .general-error-text {
        font-family: Roboto;
        font-weight: normal;
        font-size: 15px;
        line-height: 17px;
        text-align: justify;
        letter-spacing: -5%;
      }
      .contact-container-options {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .general-error {
      .general-error-text {
        font-family: Roboto;
        font-weight: normal;
        font-size: 15px;
        line-height: 17px;
        text-align: justify;
        letter-spacing: -5%;
      }
      .contact-container-options {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>

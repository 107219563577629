<template>
  <div>
    <div
      v-if="loading"
      class="content text-center ma-0 px-0 py-16"
      width="100%"
      flat
    >
      <v-progress-circular
        :size="$device.mobile ? 40 : 70"
        :width="$device.mobile ? 4 : 7"
        color="primary"
        indeterminate
      />
    </div>

    <div v-else>
      <div v-if="!select_schedule_date">
        <p
          class="text-left pt-4 spanishGray--text"
        >
          {{ $t('schedule.select_date') }}
        </p>

        <p
          class="font-weight-bold"
        >
          {{ $t('schedule.month_day') }}
        </p>

        <div
          class="calendar-container"
        >
          <v-date-picker
            v-model="select_schedule_date"
            :allowed-dates="allowedDates"
            :disabled="loadingCalendar"
            :header-date-format="customFormatHeader"
            :max="range_days.max"
            :min="range_days.min"
            :show-current="range_days.correct_day"
            @update:picker-date="updatePickedMonth"
            class="mx-auto my-0"
            locale="pt-br"
            next-icon="fa-sharp fa-solid fa-circle-chevron-right"
            no-title
            prev-icon="fa-sharp fa-solid fa-circle-chevron-left"
            @input="handleDateClick"
          />

          <div
            v-if="loadingCalendar"
            class="calendar-loader align-center d-flex justify-center ma-0 pa-0"
            flat
          >
            <v-progress-circular
              :size="$device.mobile ? 30 : 40"
              :width="$device.mobile ? 3 : 4"
              color="primary"
              indeterminate
            />
          </div>
        </div>
      </div>

      <div v-if="select_schedule_date">
        <v-row>
          <v-col cols="12" md="6" lg="4" xl="3">
            <v-list rounded :class="!$device.mobile? 'mr-5' : ''">
              <v-list-item-group
                  v-model="selected_reset_option"
              >
                <v-list-item
                  v-if="select_schedule_date"
                  value="select_new_date"
                  class="selected-schedule-list-item mt-4"
                  dense
                >
                  <v-list-item-icon class="m-0">
                    <v-icon
                        color="secondary"
                    >
                      fa-regular fa-calendar-days
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ $t('schedule.date') }}</v-list-item-title>

                    <v-list-item-subtitle v-text="schedule_date_format"></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-icon
                      size="15"
                      color="outerSpace"
                  >
                    fa-solid fa-chevron-right
                  </v-icon>
                </v-list-item>

                <v-list-item
                  v-if="select_schedule_hour"
                  value="select_new_hour"
                  class="selected-schedule-list-item mt-4"
                  dense
                >
                  <v-list-item-icon class="d-flex justify-center align-center">
                    <v-icon
                        color="secondary"
                    >
                      fa-regular fa-calendar-days
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ $t('schedule.schedules') }}</v-list-item-title>

                    <v-list-item-subtitle>{{ select_schedule_hour }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-icon
                    size="15"
                    color="outerSpace"
                  >
                    fa-solid fa-chevron-right
                  </v-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>

          <v-divider
            v-if="!$device.mobile"
            vertical
          />

          <v-col>
            <div v-if="!select_schedule_hour" :class="!$device.mobile? 'ml-8' : ''">
              <div class="mt-2">
                <v-row>
                  <v-col :class="!$device.mobile? 'pl-0' : 'pb-0'">
                    <span
                      class="available-hours-text"
                    >
                      {{ $t('schedule.available_hours_text') }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex">
                    <div class="box-select-hours d-flex">
                      <v-btn
                        v-for="(item, index) in list_schedule_hours"
                        :disabled="validatingAvailableTime(item.timestamp)" @click="setSelectSlotOption(item)"
                        :key="index"
                        class="hours-btn"
                        elevation="1"
                        x-small
                      >
                        {{ item.hour }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div v-if="select_schedule_hour">
              <div class="mt-2" v-if="is_family_medicine">
                <v-row class="d-flex flex-column justify-start">
                  <v-col cols="12" sm="8" md="5" :class="$device.mobile? 'd-flex justify-center' : ''">
                    <v-btn
                      @click="saveScheduleOption"
                      elevation="0"
                      rounded
                      class="next-btn"
                    >
                      {{ $t('general.next') }}
                    </v-btn>
                  </v-col>

                  <v-col v-if="!$device.mobile" cols="12" sm="8" md="5">
                    <v-btn rounded elevation="0" class="back-btn" @click="resetHour">
                      {{ $t('general.back') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <div class="mt-2" v-if="!is_family_medicine">
                <div v-if="select_professional">
                  <v-row>
                    <v-col cols="12" sm="8" md="7">
                      <div class="d-flex" :class="$device.mobile? 'flex-column-reverse' : ' flex-column'">
                        <v-text-field
                            :loading="loading"
                            density="compact"
                            variant="solo"
                            placeholder="Buscar por nome"
                            append-icon="fa-magnifying-glass"
                            single-line
                            hide-details
                            @keyup="searchProfessional"
                            v-model="needle_search"
                        />

                        <v-btn
                            v-if="themesList.length > 0"
                            @click="show_filter_themes_modal=true"
                            small
                            plain
                            class="filter-themes"
                        >
                          <v-icon small class="mr-1">mdi-tune</v-icon>
                          {{ $t('schedule.filters') }}
                        </v-btn>
                      </div>


                      <h5 class="professional-available">
                        {{ $t('schedule.professional_available') }}
                      </h5>

                      <p class="message-info-professional">
                        {{ $t('schedule.message_info_professional') }}
                      </p>

                      <v-list rounded>
                        <v-list-item
                          v-for="(item, i) in professionalsFilters"
                          :key="i" :value="item.id"
                          class="professional-item-list selected-schedule-list-item mt-4"
                          @click="loadingModal(item)"
                        >
                          <v-list-item-avatar>
                            <v-img :alt="`${item.name}`" :src="item.image_path"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>
                              {{ getProfessionalName(item) }}
                            </v-list-item-title>

                            <div v-if="item.themes.length == 0">
                              <v-list-item-subtitle
                                  v-if="item.crm || !program_well_being"
                                  v-text="`${item?.prof_council??''} ${item?.crm??''}`"
                              />

                              <v-list-item-subtitle
                                  v-else
                                  v-text="`${item?.roles[0]?.role_name ??''}`"
                              />

                              <v-list-item-subtitle
                                  v-if="item.not_have_available_schedule"
                                  class="unavailable"
                              >
                                {{ $t('schedule.unavailable') }}
                              </v-list-item-subtitle>
                            </div>

                            <div v-else>
                              <v-list-item-subtitle
                              >
                                {{ showProfessionalThemes(item) }}
                              </v-list-item-subtitle>

                              <v-list-item-subtitle>
                                <div>
                                  <span class="touched-more-themas">{{ $t('schedule.touched_more_themas') }}</span>

                                  <span class="unavailable"
                                        v-if="item.not_have_available_schedule">
                          {{ $t('schedule.unavailable') }}
                        </span>
                                </div>
                              </v-list-item-subtitle>
                            </div>
                          </v-list-item-content>

                          <v-icon size="15" color="outerSpace">
                            fa-solid fa-chevron-right
                          </v-icon>
                        </v-list-item>
                      </v-list>

                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <GeneralModal
      :dialog="show_info_exist_attendance"
      persistent
      width="540"
      :alert="show_info_exist_attendance"
      :has-confirm="show_info_exist_attendance"
      :confirm-text="$t('general.ok_got_it')"
      @handleConfirm="handleConfirmDialogExistAttendance"
    >
      <h2 class="text-center">
        {{ $t('general.warning') }}
      </h2>

      <p class="p-exist-attendance text-left pt-4">
        {{ $t('schedule.warning_exist_schedule_attendance.part_1') }}
      </p>

      <p class="p-exist-attendance text-left pt-4">
        {{ $t('schedule.warning_exist_schedule_attendance.part_2') }}
        <strong> {{ $t('schedule.warning_exist_schedule_attendance.part_3') }}</strong>
      </p>
    </GeneralModal>

    <GeneralModal
      :dialog="show_info_professional_detail"
      persistent
      width="549"
      :header-title="$t('schedule.select_professional')"
      :has-confirm="show_info_professional_detail"
      :has-close="show_info_professional_detail"
      :has-cancel="show_info_professional_detail"
      :confirm-text="$t('schedule.select_this_professional')"
      :cancel-text="$t('schedule.back')"
      @handleConfirm="handleConfirmProfessionalSelected"
      @handleCancel="handleCancelModal"
      @handleClose="handleCancelModal"
    >
      <h5 class="professional-available">{{ $t('schedule.data_of_professional') }}</h5>

      <v-list class="list-modal-professional mt-3">
        <v-list-item @click="handleCancelModal">
          <v-list-item-avatar>
            <v-img :alt="`${professional?.name??''}`" :src="professional?.image_path??''"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ getProfessionalName(professional) }}
            </v-list-item-title>

            <v-list-item-subtitle
              v-if="(professional && professional.crm) || !program_well_being"
              v-text="`${professional?.prof_council??''} ${professional?.crm??''}`"
            />

            <v-list-item-subtitle
              v-else
              v-text="`${professional?.roles[0]?.role_name ??''}`"
            />
          </v-list-item-content>

          <v-icon size="15" color="outerSpace">
            fa-solid fa-chevron-right
          </v-icon>
        </v-list-item>
      </v-list>

      <h5 class="professional-available mt-3">{{ $t('schedule.about_me') }}</h5>

      <fieldset class="fieldset-primary">
        <v-card-text>
          {{ professional?.about_me ?? '' }}
        </v-card-text>
      </fieldset>

      <h5
          class="professional-available"
      >
        {{ $t('schedule.themes') }}
      </h5>

      <fieldset class="fieldset-primary">
        <v-card-text>
          <v-row>
            <v-col
                cols="12"
                md="4"
                v-for="(themeChunk, index) in chunkedThemes"
                :key="index"
            >
              <ul>
                <li v-for="theme in themeChunk" :key="theme.id">
                  {{ theme.theme }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
      </fieldset>

      <h5 class="professional-available">{{ $t('schedule.training') }}</h5>

      <fieldset class="fieldset-primary">
        <v-card-text>
          {{ professional?.acronym ?? '' }}

          <v-list-item-subtitle
            v-text="`${professional?.institution??''} / ${professional?.institution_state_acronym??''}`"
          />
        </v-card-text>
      </fieldset>
    </GeneralModal>

    <ScheduleUploadFileModal
      :dialog="show_upload_file"
      @handleClose="show_upload_file=false"
    />

    <GeneralModal
        :dialog="show_filter_themes_modal"
        :header-title="$t('schedule.filters')"
        width="540"
        @handleCancel="show_filter_themes_modal = false"
        @handleConfirm="applyFilters"
    >
      <v-row>
        <v-col cols="12" class="pa-0 mb-2">
          <span class="mb-4">{{ $t('schedule.filter_description') }}</span>
        </v-col>
      </v-row>

      <v-row class="mt-2">
        <v-col cols="9" class="text-center filter-themes-description-action filter-themes-description">
          <span>{{ $t('schedule.themes') }}</span>
        </v-col>
        <v-col cols="3" class="filter-themes-description">
          <v-btn
              small
              outlined
              @click="clearFilters"
              class="text-capitalize filter-themes-btn-clear"
          >
            {{ $t('schedule.filters_clear') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-for="(theme, index) in themesList" :key="index" class="filter-themes-rows mt-2 mb-2">
        <v-col cols="9" class="text-start d-flex align-center justify-start">
          <span>{{ theme.theme_name }}</span>
        </v-col>
        <v-col cols="3" class="d-flex justify-center mt-0">
          <v-checkbox
              v-model="selected_themes_filter"
              :value="theme.theme_name"
              hide-details
          />
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
              color="primary"
              class="ma-2 card-filter-actions"
              rounded
              large
              @click="applyFilters"
          >
            {{ $t('schedule.filter_show_professionals') }}
          </v-btn>
        </v-col>
      </v-row>
    </GeneralModal>

    <GeneralModal
        :dialog="show_info_not_exist_professional"
        alert
        persistent
        width="540"
        :has-confirm="show_info_not_exist_professional"
        :confirm-text="$t('general.ok_got_it')"
        @handleConfirm="handleConfirmDialogNotExistProfessional"
    >
      <h2 class="text-center">
        Profissionais não disponíveis
      </h2>

      <p class="text-left pt-4">
        <strong>Atenção: </strong> No momento, não estão disponíveis profissionais que atendam um ou mais temas selecionados
      </p>
    </GeneralModal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import GeneralModal from '@/components/general/GeneralModal.vue';
import ScheduleUploadFileModal from '@/components/schedule/ScheduleUploadFileModal.vue';
import calendar from '@/mixins/calendar';
import consts from '@/static/js/consts';

export default {
  name: 'ScheduleFormSelectDate',
  components: {ScheduleUploadFileModal, GeneralModal},

  mixins: [calendar],

  data: () => ({
    loading: false,
    loadingCalendar: false,
    calendarInCurrentMonth: true,
    updatedTimestamp: null,
    select_schedule_date: null,
    select_schedule_hour: null,
    selected_reset_option: null,
    is_family_medicine: false,
    show_info_exist_attendance: false,
    available_slots_days: [],
    professionals: [],
    professionalsFilters: [],
    select_schedule_option: null,
    select_professional: false,
    show_info_professional_detail: false,
    professional: null,
    needle_search: '',
    show_upload_file: false,
    scheduled_appointments: [],
    schedule_role_name: 'daysProgramCalendar',
    program_well_being: false,
    firstScheduleTimestamp: null,
    show_filter_themes_modal: false,
    selected_themes_filter: [],
    themesList: [],
    show_info_not_exist_professional: false
  }),

  created() {
    this.loading = true;
  },

  mounted() {
    this.init();
  },

  methods: {
    ...mapActions('data/themes', [
      'themesGet'
    ]),

    async init() {
      this.program = this.getProgram;

      if (consts.PROGRAM_WELL_BEING_IDS.includes(this.program.id)) {
        this.program_well_being = true;
      }

      if (this.program.id === 7) {
        this.is_family_medicine = true
      }

      await this.themesGet({
        program_id: this.program.id
      });

      this.themesList = this.getThemes.themes;

      this.checkExistAttendance();
    },

    checkExistAttendance() {
      const patientLastAttendances = this.getPatientLastAttendance();

      if (patientLastAttendances?.attendances?.programs_scheduled?.length > 0) {
        const patientID = patientLastAttendances.attendances.patient_info.id

        const serviceScheduleSameProgramByPatient = patientLastAttendances.attendances.programs_scheduled.filter(
          o => o.program_id === this.program.id && o.member_id === patientID
        );

        if (serviceScheduleSameProgramByPatient.length >= +this.program.attendance_qty) {
          this.openInfoExistAttendance()
          return;
        }
      }

      if (this.attendances?.length > 0) {
        this.attendances
          .filter(o => o.user_schedule_id)
          .forEach(o => this.scheduled_appointments.push(this.$moment(o.sla)));
      }

      this.loadingCalendar = true;
      this.scheduleSlotsDays(false);
    },

    openInfoExistAttendance() {
      this.loading = false;
      this.show_info_exist_attendance = true;
    },

    handleConfirmDialogExistAttendance() {
      this.show_info_exist_attendance = false;

      const {program_info = [], program_well_being = []} = this.programsInfo.data;

      if (program_info.length + program_well_being.length === 1) {
        this.$router.push('/');
      } else {
        this.$router.push('/programs');
      }
    },

    setSelectSlotOption(value) {
      this.select_schedule_option = value
      this.select_schedule_hour = value.hour
      if (!this.is_family_medicine) {
        const schedules = this.getScheduleSlotsStorage.programCalendar;
        this.professionals = schedules.scheduleInfo.reduce((result, item) => {
          if (
              value.user_schedules_ids.find((userScheduleId) => userScheduleId == item.schedule_id)
            ) {
            result.push(item);
          }

          return result;
        }, []);

        this.professionalsFilters = this.professionals;
      }

      this.select_professional = true;
    },

    saveScheduleOption() {
      this.setDatetime(this.select_schedule_option.timestamp)
      this.setUserSchedulesIds(this.select_schedule_option.user_schedules_ids)

      if (!this.program.is_program_well_being && this.getFiles.length < 5) {
        this.openUploadFile();
      } else {
        this.$router.push('/schedule/details');
      }
    },

    getProfessionalName(item) {
      if (!item) return '';

      let programList = [48, 57]; // "Psicologia" and "Psicologia por Vídeo"
      if (programList.includes(this.program.id)) {
        return item?.name??'';
      }
      
      return item.crm || !this.program_well_being
        ? `${this.$t('schedule.MD')} ${item.name}`
        : item?.name??'';
    },

    openUploadFile() {
      this.show_upload_file = true;
    },

    loadingModal(item) {
      this.professional = item;
      this.show_info_professional_detail = true;
    },

    handleCancelModal() {
      this.professional = null;
      this.show_info_professional_detail = false;
    },

    handleConfirmProfessionalSelected() {
      this.setProfessional(this.professional);
      this.show_info_professional_detail = false;
      this.saveScheduleOption()
    },

    searchProfessional() {
      if (this.needle_search != null && Array.from(this.needle_search).length > 0) {
        this.professionalsFilters = this.professionals.filter((professional) => {
          return professional.name.toLowerCase().includes(this.needle_search.toLowerCase());
        });
      } else if (this.needle_search === '') {
        this.professionalsFilters = this.professionals
      }
    },

    async getSlotsFilter() {
      const doubleBooking = this.getCheckDoubleBooking.double_booking;
      return {
        rules: [
          {
            name: "programCalendar",
            filters: {
              program_id: this.program.id,
              occupation_id: this.program.occupation_id,
              speciality_id: this.program.speciality_id,
              timestamp: this.updatedTimestamp || this.$moment().startOf('day').unix(),
              days_ahead: this.daysAhead,
              double_booking: doubleBooking,
              company_id: this.getCompanyId
            }
          }
        ]
      };
    },

    handleDateClick() {
      this.schedule_role_name = 'programCalendar';
      if (this.select_schedule_date) {
        const selectedDate = new Date(this.select_schedule_date);
        selectedDate.setUTCHours(0, 0, 0, 0);
        selectedDate.setHours(selectedDate.getHours() + 3);
        this.firstScheduleTimestamp = Math.floor(selectedDate.getTime() / 1000);
      }
      this.loading = true;
      this.loadingCalendar = true;
      this.scheduleSlotsReview(false);
    },

    async getDaysFilter() {
      const doubleBooking = this.getCheckDoubleBooking.double_booking;
      return {
        "rules": [
          {
            name:"daysProgramCalendar",
            filters:{
              program_id: this.program.id,
              occupation_id: this.program.occupation_id,
              speciality_id: this.program.speciality_id,
              timestamp: this.updatedTimestamp || this.$moment().startOf('day').unix(),
              days_ahead: this.updatedTimestamp ? this.daysAhead - 1 : this.daysAhead,
              double_booking: doubleBooking,
              timezone: "America/Sao_Paulo",
              company_id: this.getCompanyId
            }
          }
        ]
      };
    },

    async getSingleDayFilter() {
      const doubleBooking = this.getCheckDoubleBooking.double_booking;
      return {
        "rules": [
          {
            name:"programCalendar",
            filters:{
              program_id: this.program.id,
              occupation_id: this.program.occupation_id,
              speciality_id: this.program.speciality_id,
              timestamp: this.firstScheduleTimestamp || this.$moment().startOf('day').unix(),
              days_ahead: 1,
              double_booking: doubleBooking,
              company_id: this.getCompanyId
            }
          }
        ]
      };
    },

    updatePickedMonth(value) {
      let newValueMoment = this.$moment(`${value}-01`);
      let isCurrentMonth = this.$moment().month() === newValueMoment.month();

      if (this.calendarInCurrentMonth == isCurrentMonth) {
        // Prevents double calls for EP
        return;
      }

      this.calendarInCurrentMonth = isCurrentMonth;

      this.updatedTimestamp = this.calendarInCurrentMonth
        ? this.$moment().unix()
        : newValueMoment.unix();

      this.updatedTimestamp = this.updatedTimestamp < this.firstAvailableDate ? this.firstAvailableDate : this.updatedTimestamp;
      this.loadingCalendar = true;

      this.schedule_role_name = 'daysProgramCalendar';
      this.scheduleSlotsDays(false);
    },

    handleScheduleChange(type) {
      if (type === 'by_schedule' && this.select_schedule_date){
        this.selected_reset_option = 'select_new_date'
      }
    },

    showProfessionalThemes(item) {
      if (!item.themes || item.themes.length === 0) return '';

      // Filtra temas com affinity não nulo, clona o array, aplica ordenação e mapeamento
      const topThemes = [...item.themes]
          .filter(theme => theme.affinity !== null) // Ignora temas com affinity nulo
          .sort((a, b) => a.affinity - b.affinity)  // Ordena por afinidade
          .slice(0, 3)  // Limita aos 3 principais temas
          .map((theme) =>
              theme.theme.length > 74 ? `${theme.theme.substring(0, 74)}...` : theme.theme
          );

      // Combina os temas com ' | ' e retorna o resultado
      return topThemes.join(' | ');
    },

    clearFilters() {
      this.selected_themes_filter = [];
    },

    applyFilters() {
      this.professionalsFilters = this.selected_themes_filter.length > 0
          ? this.professionals.filter(professional =>
              professional.themes.some(theme => this.selected_themes_filter.includes(theme.theme))
          ) : this.professionals;

      this.show_filter_themes_modal = false;

      this.show_filter_themes_modal = false;

      if (this.professionalsFilters.length === 0) {
        this.show_info_not_exist_professional = true;
        this.clearFilters()
        this.applyFilters()
      }
    },
    handleConfirmDialogNotExistProfessional() {
      this.show_info_not_exist_professional = false;
    },
  },

  computed: {
    ...mapGetters('data/patient', {
      attendances: 'getAttendances',
      firstAvailableDate: 'getFirstAvailableDate'
    }),

    ...mapGetters('data/schedule', {
      programInfo: 'getProgram',
      programsInfo: 'getPrograms',
      getFiles: 'getFiles',
      getScheduleType: 'getScheduleType',
    }),

    ...mapGetters('data/themes', {
      getThemes: 'getThemes',
    }),
    chunkedThemes() {
      const themes = this.professional?.themes || [];
      const chunkSize = 4;
      const result = [];

      for (let i = 0; i < themes.length; i += chunkSize) {
        result.push(themes.slice(i, i + chunkSize));
      }

      return result;
    },
  },

  watch :{
    getScheduleType(newValue) {
      this.handleScheduleChange(newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.v-picker {
  border: 1px solid $silverSand !important;
}

.v-list {
  padding: 0 !important;
}

.selected-schedule-list-item {
  border: 1px solid;
  border-color: $spanishGray;
  border-radius: 25px;
}

.available-hours-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: $outerSpace;
}

.box-select-hours {
  justify-content: flex-start;
  flex-wrap: wrap !important;
  border: 1px solid;
  border-color: #D8D8D8;
  min-height: 10em;
  overflow-y: auto;
  height: 237px;
  width: 402px;
  padding: 24px 16px;
  gap: 16px;

  &::-webkit-scrollbar {
    width: 0! important
  }

  @media screen and (max-device-width: 640px) {
    height: 237px;
    width: 100%;
    padding: 15px 6px;
    gap: 0.58em;
  }
}

.hours-btn {
  background: #FFFFFF !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: $outerSpace;
  width: 71px !important;
  height: 36px !important;
  padding: 0px 8px !important;

  @media screen and (max-device-width: 640px) {
    height: 237px;
    width: 319px;
    font-size: 18px;
    margin: 5px 0px 9px 0px;
  }
}

.next-btn {
  background-color: $outerSpace !important;
  color: $antiFlashWhite !important;
  width: 12em !important;
}

.back-btn {
  background-color: white !important;
  color: $outerSpace !important;
  width: 12em !important;
  border-color: $spanishGray !important;
  border: 0.6px solid;
}

.calendar-container {
  position: relative;
}

.calendar-loader {
  background-color: #FFFFFF90;
  bottom: 0;
  height: 284px;
  position: absolute;
  width: 292px;
}

/* For Tablet View */
@media screen and (min-device-width: 641px) and (max-device-width: 1024px) {

}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .v-picker {
    width: 100%;
  }

  .calendar-loader {
    width: 100%;
  }
}

.professional-available {
  padding-top: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: $outerSpace;
}

.message-info-professional {
  padding-top: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: $outerSpace;
}

.fieldset-primary {
  margin-top: 10px;
  border-width: 1px;
  color: $outerSpace;
}

.list-modal-professional {
  border-radius: 7em !important;
  border: 1px solid $spanishGray !important;
}

.professional-item-list{
  border: 1px solid $spanishGray;
  border-radius: 25px;
  margin-top: 10px;
  height: 4.6em;
}

.filter-themes{
  margin-top: 10px;
  height: 4.6em;
  font-size: 16px;
  font-weight: 700;
  color: $outerSpace;
}

.filter-themes-description-action{
  background-color: $spanishGray;
  font-size: 14px;
  text-transform: uppercase !important;
}

.filter-themes-description{
  height: 5px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  font-weight: bold;
}

.filter-themes-btn-clear{
  height: 2em !important;
  border-color: $spanishGray !important;
  border-radius: 0px !important;
}

.filter-themes-rows{
  height: 2em;
}

.filter-themes-cols{
  height: 5em !important;
}

</style>

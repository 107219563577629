<template>
  <div
    class="whatsapp-button pa-0"
    :max-width="maxWidth"
    :max-heigh="maxHeight"
    :width="width"
    :height="height"
  >
  <v-btn :max-width="maxWidth"
         :max-heigh="maxHeight"
         :width="width"
         :height="height"
         class="whatsapp-btn" @click="gotoWhatsapp">
    <div class="whatsapp-btn-content">
      <v-icon class="whatsapp-icon mb-1 mt-3">fa-brands fa-whatsapp</v-icon>
      <p class="whatsapp-text">WHATSAPP</p>
    </div>
  </v-btn>
  </div>
</template>

<script>

  export default {
    name: 'WhatsappButton',

    props: {
      // Modal's max width
      maxWidth: {
        type: String,
        default: 'auto'
      },

      // Modal's width
      width: {
        type: String,
        default: 'auto'
      },

      // Modal's max height
      maxHeight: {
        type: String,
        default: 'auto'
      },

      // Modal's height
      height: {
        type: String,
        default: 'auto'
      },
    },

    data: () => ({
      whatsappPhone: "5511944849739"
    }),

    created() {

    },

    methods: {
      gotoWhatsapp() {
        window.open(`https://wa.me/${this.whatsappPhone}`, '_blank');
      }
    },

    computed: {
    },
  };
</script>

<style lang="scss" scoped>
  .whatsapp-button {
    .whatsapp-btn {
      background: #01AF3D;
    }
    .whatsapp-btn-content {
      display: flex;
      flex-flow: column;
    }
    .whatsapp-icon {
      font-weight: 400;
      font-size: 35px;
      line-height: 35px;
      color: white;

    }
    .whatsapp-text {
      font-family: "Roboto";
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
      text-align: center;
      color: white;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .whatsapp-button {
      .whatsapp-btn {
        background: #01AF3D;
      }
      .whatsapp-btn-content {
        display: flex;
        flex-flow: column;
      }
      .whatsapp-icon {
        font-weight: 400;
        font-size: 35px;
        line-height: 35px;
        color: white;

      }
      .whatsapp-text {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: white;
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .whatsapp-button {
      .whatsapp-btn {
        background: #01AF3D;
      }
      .whatsapp-btn-content {
        display: flex;
        flex-flow: column;
      }
      .whatsapp-icon {
        font-weight: 400;
        font-size: 35px;
        line-height: 35px;
        color: white;

      }
      .whatsapp-text {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: white;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .whatsapp-button {
      .whatsapp-btn {
        background: #01AF3D;
      }
      .whatsapp-btn-content {
        display: flex;
        flex-flow: column;
      }
      .whatsapp-icon {
        font-weight: 400;
        font-size: 35px;
        line-height: 35px;
        color: white;

      }
      .whatsapp-text {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: white;
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .whatsapp-button {
      .whatsapp-btn {
        background: #01AF3D;
      }
      .whatsapp-btn-content {
        display: flex;
        flex-flow: column;
      }
      .whatsapp-icon {
        font-weight: 400;
        font-size: 35px;
        line-height: 35px;
        color: white;

      }
      .whatsapp-text {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: white;
      }
    }
  }
</style>
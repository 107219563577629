<template>
  <div
    class="supportphone-button pa-0"
    :max-width="maxWidth"
    :max-heigh="maxHeight"
    :width="width"
    :height="height"
  >
  <v-btn
      :max-width="maxWidth"
      :max-heigh="maxHeight"
      :width="width"
      :height="height"
      class="supportphone-btn" @click="redirectToPhoneApp">
    <div class="supportphone-btn-content">
      <v-icon class="supportphone-icon mb-1" v-bind:class="{' mt-3': !isMobileDevice()}">fa-solid fa-phone</v-icon>
      <p class="supportphone-text mb-0">{{phone}}</p>
    </div>
  </v-btn>
  </div>
</template>

<script>

  export default {
    name: 'SupportPhoneButton',

    props: {
      // Modal's max width
      maxWidth: {
        type: String,
        default: 'auto'
      },

      // Modal's width
      width: {
        type: String,
        default: 'auto'
      },

      // Modal's max height
      maxHeight: {
        type: String,
        default: 'auto'
      },

      // Modal's height
      height: {
        type: String,
        default: 'auto'
      },
    },

    data: () => ({
      phone: "0800 835 2362"
    }),

    created() {

    },

    methods: {
      isMobileDevice() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|iphone|ipad|ipod/i.test(userAgent);
      },
      redirectToPhoneApp() {
        if (this.isMobileDevice()) {
          window.location.href = 'tel:' + this.phone;
        }
      }
    },

    computed: {
    },
  };
</script>

<style lang="scss" scoped>
  .supportphone-button {
    .supportphone-btn {
      background: white;
      border-color: #C4C4C4;
    }
    .supportphone-btn-content {
      display: flex;
      flex-flow: column;
    }
    .supportphone-icon {
      font-weight: 900;
      font-size: 27px;
      line-height: 27px;
      color: #00B7E6;

    }
    .supportphone-text {
      font-family: "Roboto";
      font-weight: 700;
      font-size: 14px;
      line-height: 27px;
      text-align: center;
      color: #444444;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .supportphone-button {
      .supportphone-btn {
        background: white;
        border-color: #C4C4C4;
      }
      .supportphone-btn-content {
        display: flex;
        flex-flow: column;
      }
      .supportphone-icon {
        font-weight: 900;
        font-size: 30px;
        line-height: 30px;
        color: #00B7E6;

      }
      .supportphone-text {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: #444444;
        letter-spacing: 0px;
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .supportphone-button {
      .supportphone-btn {
        background: white;
        border-color: #C4C4C4;
      }
      .supportphone-btn-content {
        display: flex;
        flex-flow: column;
      }
      .supportphone-icon {
        font-weight: 900;
        font-size: 30px;
        line-height: 30px;
        color: #00B7E6;

      }
      .supportphone-text {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: #444444;
        letter-spacing: 0px;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .supportphone-button {
      .supportphone-btn {
        background: white;
        border-color: #C4C4C4;
      }
      .supportphone-btn-content {
        display: flex;
        flex-flow: column;
      }
      .supportphone-icon {
        font-weight: 900;
        font-size: 30px;
        line-height: 30px;
        color: #00B7E6;

      }
      .supportphone-text {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: #444444;
        letter-spacing: 0px;
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .supportphone-button {
      .supportphone-btn {
        background: white;
        border-color: #C4C4C4;
      }
      .supportphone-btn-content {
        display: flex;
        flex-flow: column;
      }
      .supportphone-icon {
        font-weight: 900;
        font-size: 30px;
        line-height: 30px;
        color: #00B7E6;

      }
      .supportphone-text {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: #444444;
        letter-spacing: 0px;
      }
    }
  }
</style>
<template>
  <v-container class="attendance-history-details-view d-flex flex-column pa-0" max-width="100%">
    <v-container :class="[ $device.mobile ? 'py-10 px-4' : 'pa-16' ]">
      <h5 class="text-h5 mb-5 font-weight-bold">
        {{ $t('general.history_details') }}
      </h5>

      <div class="d-flex flex-column">
        <h5 class="font-weight-bold outerSpace--text">
          {{ $t('attendances_details.professional').toUpperCase() }}
        </h5>

        <div class="d-flex align-center mt-2">
          <!-- Professional -->
          <div>
            <v-list-item
              v-if="doctor"
              class="professional-list-item mt-4"
              dense
            >
              <v-list-item-avatar>
                <v-img
                  :alt="`${doctor.name}`"
                  :src="doctor.image_path"
                />
              </v-list-item-avatar>

              <v-list-item-content
                v-if="doctor.crm || !program_well_being"
              >
                <v-list-item-title>
                  {{ getProfessionalName(doctor) }}
                </v-list-item-title>

                <v-list-item-subtitle
                  v-text="`${doctor?.prof_council??''} ${doctor?.crm??''}`"
                />
              </v-list-item-content>

              <v-list-item-content
                v-else
              >
                <v-list-item-title>
                  {{doctor.name}}
                </v-list-item-title>

                <v-list-item-subtitle
                  v-text="`${doctor.role}`"
                />
              </v-list-item-content>
            </v-list-item>
          </div>

         <!-- NumberCase -->
          <div class="mt-1 ml-3">
            <div class="d-flex flex-column">
              <h5 class="number-case justify-center">
                {{ $t('general.case_number').toUpperCase() }}
              </h5>

              <h5 class="mb-1 ml-1">
                n{{ attendanceInfo.case_id }}
              </h5>
            </div>
          </div>
        </div>

        <v-row class="d-flex ma-0 pt-10 px-0">
          <!-- INIT RESUME -->
          <div
            class="card-content ma-0 pa-0"
          >
            <h6 class="text-h6 mb-5">
              {{ $t('general.resume_medical').toUpperCase() }}
            </h6>

            <v-card
              :class="[ $device.mobile ? 'mr-0' : 'mr-4' ]"
              :width="$device.mobile ? '100%' : '360'"
              class="overflow-y-auto align-center info-box pa-2 mb-4 mt-1"
              height="235"
            >
              <v-card-text>
                <div class="d-flex flex-column justify-center">
                  <p class="text-h6 mb-5" id="label-p-title">{{ translate }}</p>

                  <p class="outerSpace--text">
                    <span class="font-weight-normal spanishGray--text">{{ $t('general.history_patient') }}</span> {{ social_name || name }}
                  </p>

                  <p class="ma-0 outerSpace--text">
                    <span class="font-weight-normal spanishGray--text">{{ $t('general.history_date') }}</span> {{ date }}
                  </p>

                  <p class="ma-0 outerSpace--text">
                    <span class="font-weight-normal spanishGray--text">{{ $t('general.history_hour') }}</span> {{ hour }}h
                  </p>

                  <p class="ma-0 outerSpace--text" v-if="validStatus">
                    <span class="font-weight-normal spanishGray--text">{{ $t('general.history_status') }}</span> {{ $t('general.' + attendanceInfo.status_name) }}
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <!-- END RESUME -->

          <!-- INIT PRESCRIPTION -->
          <div
            v-if="prescription"
            class="card-content ma-0 pa-0"
          >
            <h6 class="text-h6 mb-5">
              {{ $t('general.prescription').toUpperCase() }}
            </h6>

            <v-card
              :class="[ $device.mobile ? 'mr-0' : 'mr-4' ]"
              :width="$device.mobile ? '100%' : '360'"
              class="overflow-y-auto align-center info-box pa-2 mb-4"
              height="235"
            >
              <v-row no-gutters class="ma-0 pa-0">
                <div class="d-flex flex-column justify-center mb-2">
                  <!-- <div v-if="medicationsList">
                    <p class="text-p multiline-ellipsis" v-for="(med, index) in medicationsList" v-bind:key="index">
                      {{ med.Nome + ' - ' + med.Posologia }}
                    </p>
                    
                  </div> -->
                  <p>
                    {{ $t('general.history_prescription') }}
                  </p>

                  <v-btn @click="download(prescription)" class="btn-pdf font-weight-bold mx-3"
                    color="primary" dark height="46" outline-border rounded width="300">
                    <v-icon class="mr-2" color="white" small>
                      mdi-eye-outline
                    </v-icon>

                    {{ $t('general.open_pdf') }}
                  </v-btn>
                </div>
              </v-row>
            </v-card>
          </div>
          <!-- END PRESCRIPTION -->

          <!-- INIT ATTEST -->
          <div
            v-if="attest"
            class="card-content ma-0 pa-0"
          >
            <h6 class="text-h6 mb-5">
              {{ $t('general.attest').toUpperCase() }}
            </h6>

            <v-card
              :class="[ $device.mobile ? 'mr-0' : 'mr-4' ]"
              :width="$device.mobile ? '100%' : '360'"
              class="overflow-y-auto align-center info-box pa-2 mb-4 mt-1"
              height="235"
            >
              <v-row no-gutters class="ma-0 pa-0">
                <div class="d-flex flex-column justify-center mb-2">
                  <div v-if="medicalAttest" id="log" v-html="medicalAttest">
                  </div>
                  <p v-else>
                    {{ $t('general.history_attest') }}
                  </p>

                  <v-btn @click="download(attest)" class="btn-pdf font-weight-bold mx-3" color="primary"
                    dark height="46" outline-border rounded width="300">
                    <v-icon class="mr-2" color="white" small>
                      mdi-eye-outline
                    </v-icon>

                    {{ $t('general.open_pdf') }}
                  </v-btn>
                </div>
              </v-row>
            </v-card>
          </div>
          <!-- END ATTEST -->

          <!-- INIT RELATORIO MEDICO -->
          <div
            v-if="relatorio_medico && medicalReport.description"
            class="card-content ma-0 pa-0"
          >
            <h6 class="text-h6 mb-5">
              {{ $t('general.report_medical').toUpperCase() }}
            </h6>

            <v-card
              :class="[ $device.mobile ? 'mr-0' : 'mr-4' ]"
              :width="$device.mobile ? '100%' : '360'"
              class="overflow-y-auto align-center info-box pa-2 mb-4"
              height="235"
            >
              <v-row no-gutters class="ma-0 pa-0">
                <div class="text-p multiline-ellipsis d-flex flex-column justify-center mb-2">
                  <p class="text-p multiline-ellipsis">
                    {{ medicalReport.description }}
                  </p>

                  <v-btn @click="download(relatorio_medico)" class="btn-pdf font-weight-bold mx-3"
                    color="primary" dark height="46" outline-border rounded width="300">
                    <v-icon class="mr-2" color="white" small>
                      mdi-eye-outline
                    </v-icon>

                    {{ $t('general.open_pdf') }}
                  </v-btn>
                </div>
              </v-row>
            </v-card>
          </div>
          <!-- END RELATORIO MEDICO -->

          <!-- INIT EXAMS -->
          <div
            v-if="exams && exam"
            class="card-content ma-0 pa-0"
          >
            <h6 class="text-h6 mb-5">
              {{ $t('general.exams').toUpperCase() }}
            </h6>

            <v-card
              :class="[ $device.mobile ? 'mr-0' : 'mr-4' ]"
              :width="$device.mobile ? '100%' : '360'"
              class="overflow-y-auto align-center info-box pa-2 mb-4"
              height="235"
            >
              <v-row no-gutters class="ma-0 pa-0">
                <div class="text-p multiline-ellipsis d-flex justify-center mb-2">
                  <ul class="text-p multiline-ellipsis">
                    <li v-for="(exam, index) in examsList" v-bind:key="index">{{ exam }}</li>
                  </ul>
                </div>

                <v-btn @click="download(exam)" class="btn-pdf font-weight-bold mx-3" color="primary" dark
                  height="46" outline-border rounded width="300">
                  <v-icon class="mr-2" color="white" small>
                      mdi-eye-outline
                  </v-icon>

                  {{ $t('general.open_pdf') }}
                </v-btn>
              </v-row>
            </v-card>
          </div>
          <!-- END ATTEST -->
        </v-row>

        <v-row class="ma-0 ma-0">
          <div class="d-flex flex-column justify-center mb-2 pt-10">
            <p class="text-md-left">
              {{ $t('general.history_doubt') }}
            </p>
            <p class="text-md-left">
              {{ $t('general.history_contact') }}
            </p>
          </div>
        </v-row>

        <v-row class="ma-0 ma-0">
          <div class="d-flex">
            <WhatsappButton
                :height="$device.mobile ? '90px' : '90px'"
                :max-height="$device.mobile ? '90px' : '90px'"
                :width= "$device.mobile ? '101px' : '147px'"
                :max-width= "$device.mobile ? '101px' : '147px'"
                class="mr-3"
            ></WhatsappButton>
            <SupportPhoneButton
                :height="$device.mobile ? '90px' : '90px'"
                :max-height="$device.mobile ? '90px' : '90px'"
                :width= "$device.mobile ? '101px' : '147px'"
                :max-width= "$device.mobile ? '101px' : '147px'"
            ></SupportPhoneButton>
          </div>
        </v-row>
        <v-row class="ma-0 mt-8">
          <div class="d-flex mt-0">
            <SupportEmail></SupportEmail>
          </div>
        </v-row>
      </div>
    </v-container>

    <!-- Warning info for sac -->
    <GeneralModal
      @handleCancel="handleCloseSac"
      @handleClose="handleCloseSac"
      @handleConfirm="handleCloseSac"
      :dialog="sacDialog"
      :header-close="handleCloseSac"
      :header-title="$t('home.support_title')"
      background-color="antiFlash"
      has-header-close
      invert-actions
      width="430"
    >
      <div class="d-flex flex-column justify-center pt-0">
        <v-img
          :src="require('@/assets/general/phone_code_success.svg')"
          contain
        />

        <h4
          class="font-weight-bold justify-center text-md-justify text-h4 pt-0"
        >
          <v-icon
            class="mr-2"
            color="blue"
            size="30"
          >
            mdi-phone
          </v-icon>

          {{ $t('general.home_sac') }}
        </h4>

        <p
          class="font-weight-bold justify-center text-md-center pt-2"
          color="outerSpace"
        >
          {{ $t('general.history_sac_call') }}
        </p>

        <p
          class="font-weight-bold justify-center text-md-center pt-0"
          color="outerSpace"
        >
          {{ $t('general.history_sac_week') }}
        </p>

        <div class="d-flex justify-center pt-0">
          <v-btn
            @click="handleCloseSac"
            class="font-weight-bold justify-center"
            height="40"
            outlined
            rounded
            width="170"
          >
            {{ $t('general.back') }}
          </v-btn>
        </div>
      </div>
    </GeneralModal>

    <!-- Footer -->
    <HomeFooter />
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import HomeFooter from '@/components/home/HomeFooter';
  import GeneralModal from '@/components/general/GeneralModal';
  import helpers from '@/mixins/helpers';
  import consts from '@/static/js/consts';
  import WhatsappButton from "@/components/general/WhatsappButton.vue";
  import SupportPhoneButton from "@/components/general/SupportPhoneButton.vue";
  import SupportEmail from "@/components/general/SupportEmail.vue";

  export default {
    name: 'AttendanceHistoryDetailsView',

    mixins: [helpers],

    components: {
      SupportEmail,
      SupportPhoneButton,
      WhatsappButton,
      HomeFooter,
      GeneralModal
    },

    data: () => ({
      loading: false,
      sacDialog: false,
      exams: null,
      medical: null,
      documents: null,
      examsList: [],
      medicalReport: [],
      attest: null,
      relatorio_medico: null,
      exam: null,
      prescription: null,
      attendanceInfo: null,
      date: null,
      hour: null,
      translate: null,
      program: null,
      social_name: null,
      name: null,
      doctor: null,
      validStatus: false,
      medications: null,
      posology: null,
      certificate: null,
      medicalAttest: null,
      medicationsList: [],
      program_well_being: false
    }),

    created() {
      this.documents = this.history.documents;
      this.attendanceInfo = this.history;
      this.programInfo(this.history);
    },

    methods: {
      ...mapMutations('data/general', [
        'setDoctorsInfo',
        'setGeneralError',
        'setGeneralErrorRedirectRoute',
        'setHistoryAttendance',
        'setProgramsInfo',
      ]),

      ...mapActions('data/general', [
        'programGetInfo'
      ]),

      handleBack() {
        this.$router.push('/');
      },
      programInfo(attendance) {
        if (attendance) {
          let payload = {
            case_attendance_id: attendance.id
          };

          this.programGetInfo(payload).then(() => {
            this.social_name = this.attendanceInfo.patient.social_name;
            this.name = this.attendanceInfo.patient.name ?? this.attendanceInfo.patient.nome;
            if (this.history) {
              if (this.history.emr_prescription && this.history.emr_prescription.length > 0) {
                this.exams = this.parseJsonRecursive(this.history.emr_prescription[0].exams);
                this.medications = this.parseJsonRecursive(this.history.emr_prescription[0].medications);
                if (this.medications && this.medications.length > 0) {
                  this.medications.forEach(med => {
                  this.medicationsList.push(med);
                });
                }
                this.certificate = this.parseJsonRecursive(this.history.emr_prescription[0].certificate);
                if (this.certificate) {
                   this.medicalAttest = this.certificate.TextoCompleto;
                }

              }

              if (this.history.emr_relatorio_medico && this.history.emr_relatorio_medico.length > 0) {
                this.medicalReport = this.history.emr_relatorio_medico[0];
              }

              if (this.exams && this.exams.length > 0) {
                this.exams.forEach(exam => {
                  this.examsList.push(exam.Nome);
                });
              }

              this.date = this.formatDate(this.attendanceInfo.due_date);
              this.hour = this.formatHour(this.attendanceInfo.due_time);
            }
          }).catch((error) => {
            console.error(error);
            this.setGeneralError(true);
            this.setGeneralErrorRedirectRoute('home');
          }).finally(() => {
            if (this.programs && this.programs.length > 0) {
              this.translate = this.programs.find(pg => this.attendanceInfo.program_id == pg.id);

              if (this.translate && this.translate.translate.length > 0) {
                this.translate = this.translate.translate[0].translate;
              } else {
                this.translate = this.attendanceInfo.program_inf_description;
              }
            }

            if (this.doctors && this.doctors.length > 0) {
              this.doctor = this.doctors.find(doctor =>
                doctor.id == this.attendanceInfo.doctor_id || doctor.id == this.attendanceInfo.user_id);
                if (consts.PROGRAM_WELL_BEING_IDS.includes(this.attendanceInfo.program_id)) {
                  this.program_well_being = true;
                }
            }

            if (consts.DEFAULT_STATUS.includes(this.attendanceInfo.status_name)) {
                this.validStatus = true;
            }

            this.formatDocuments();
            this.loading = false;
          });
        }
      },

      formatDocuments() {
        if (this.documents) {
          this.attest = this.documents['declaracao_medica'].document_certified_relative_url;
          this.exam = this.documents['exams'].document_certified_relative_url;
          this.relatorio_medico = this.documents['relatorio_medico'].document_certified_relative_url;
          this.prescription = this.documents['prescription'].document_certified_relative_url;
        }
      },

      padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      },

      formatDate(date) {
        date = new Date(date);

        return (
          [
            this.padTo2Digits(date.getDate()),
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getFullYear()),
          ].join('/')
        );
      },

      formatHour(date) {
        let hours = date.split(":");
        date = new Date();
        date.setUTCHours(hours[0]);
        date.setUTCMinutes(hours[1]);
        date.setUTCSeconds(hours[2]);
        return (
          [
            this.padTo2Digits(date.getHours()),
            this.padTo2Digits(date.getMinutes()),
          ].join(':')
        );
      },

      download(file) {
        window.location.href = file;
      },

      getProfessionalName(item) {
        if (!item) return '';

        let programList = [48, 57]; // "Psicologia" and "Psicologia por Vídeo"
        if (programList.includes(this.attendanceInfo.program_id)) {
          return item?.name??'';
        }
        
        return item.crm || !this.is_program_well_being
          ? `${this.$t('schedule.MD')} ${item.name}`
          : item?.name??'';
      },

      back() {
        this.$router.push({ path: '/attendances' });
      },

      sac() {
        this.sacDialog = true;
      },

      handleCloseSac() {
        this.sacDialog = false;
      },
    },

    computed: {
      ...mapGetters('data/general', {
        programs: 'getProgramsInfo',
        history: 'getHistoryAttendance',
        doctors: 'getDoctorsInfo'
      }),

      ...mapGetters('data/patient', {
        profile: 'getProfile',
      }),
    }
  };
</script>

<style lang="scss" scoped>
  .attendance-history-details-view {
    .professional-list-item {
      border: 1px solid;
      border-color: #9C9C9C;
      border-radius: 25em;
    }

    #label-p-title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      color: #444444;
      padding-left: 5px;
      padding-top: 5px;
    }

    #label-p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 13px;
      color: #444444;
      margin-left: 5px;
    }

    #label-p-content {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 13px;
      color: #9C9C9C;
    }

    .number-case{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 13px;
      color: #9C9C9C;
    }

    .img-success {
      max-width: 436px;
    }

    .btn-pdf {
      position: absolute;
      bottom: 10%;
    }
    .text-p {
      padding: 2px 5px;
      /* Both of the following are required for text-overflow */
      white-space: nowrap;
      overflow: hidden;
    }

    .multiline-ellipsis {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
      white-space: pre-wrap; /* let the text wrap preserving spaces */
    }

  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
    .attendance-history-details-view {
      // 
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .attendance-history-details-view {
      .card-content {
        width: 100%;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    .attendance-history-details-view {
      .card-content {
        width: 100%;
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
    .attendance-history-details-view {
      .card-content {
        width: 100%;
      }
    }
  }
</style>

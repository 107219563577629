<template>
  <v-container
    class="login-footer ma-o pa-0 d-flex align-center justify-center"
  >
    <v-btn
      @click="$emit('handlePrivacyPolicy')"
      class="privacy-policy-button cy-privacy-policy-button button pa-0 mr-4"
      text
      height="36"
    >
      {{ $t('login.privacy_policy') }}
    </v-btn>

    <v-divider
      class="secondary"
      vertical
    />


    <v-divider
      class="secondary"
      vertical
    />

    <v-btn
      @click="$emit('handleConsentTerm')"
      class="consent-term-button cy-consent-term-button button pa-0 ml-4 mr-4"
      text
      height="36"
    >
      {{ $t('login.consent_term') }}
    </v-btn>
    <v-divider
        class="secondary"
        vertical
    />
    <v-btn
        @click="$emit('handleTalkWithUs')"
        class="privacy-policy-button cy-privacy-policy-button button pa-0 ml-4"
        text
        height="36"
    >
      {{ $t('login.talk_with_us') }}
    </v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'LoginFooter',

    props: {
      // Function to handle privacy policy action
      handlePrivacyPolicy: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle consent term action
      handleConsentTerm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      handleTalkWithUs: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      // 
    }),


  };
</script>

<style lang="scss" scoped>
  .login-footer {
    .button {
      text-align: left;
      font-size: 13px;
      white-space: pre-wrap;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .login-footer {
      .button {
        text-align: center;
        font-size: 11px;
        white-space: pre-wrap;
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .login-footer {
      .button {
        text-align: center;
        font-size: 11px;
        white-space: pre-wrap;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .login-footer {
      .button {
        text-align: center;
        font-size: 11px;
        white-space: pre-wrap;
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .login-footer {
      .button {
        text-align: center;
        font-size: 11px;
        white-space: pre-wrap;
      }
    }
  }
</style>
